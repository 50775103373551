<template>
  <div class="mt-[113px] flex justify-center md:space-x-[10px]">
    <NuxtImg
      ref="pig"
      src="/images/logo-icon-small.png"
      class="hidden w-full md:block md:h-[176px] md:w-[176px] md:-scale-x-100"
      width="200"
      height="200"
    />
    <div class="w-full max-w-[335px]">
      <div
        class="text-shadow mb-[27px] text-center font-Kontesa text-h1-mobile font-bold uppercase text-white md:text-3xl md:!leading-none"
      >
        Sign In
      </div>

      <form class="space-y-[27px]" @submit="submit">
        <div class="space-y-2">
          <FormField v-slot="{ field }" name="email">
            <FormItem>
              <FormLabel class="sr-only">Email</FormLabel>
              <FormControl>
                <Box>
                  <input
                    type="email"
                    class="w-full rounded-[inherit] px-[14px] py-[17px] font-bold text-[#16114F] placeholder-[#D5D5D5] focus-visible:outline-none"
                    placeholder="Email address"
                    v-bind="field"
                  />
                </Box>
              </FormControl>
              <FormMessage />
            </FormItem>
          </FormField>
          <FormField v-slot="{ field }" name="password">
            <FormItem>
              <FormLabel class="sr-only">Password</FormLabel>
              <FormControl>
                <Box>
                  <input
                    type="password"
                    class="w-full rounded-[inherit] px-[14px] py-[17px] font-bold text-[#16114F] placeholder-[#D5D5D5] focus-visible:outline-none"
                    placeholder="Password"
                    v-bind="field"
                  />
                </Box>
              </FormControl>
              <FormMessage />
            </FormItem>
          </FormField>
        </div>

        <div class="flex items-center justify-center">
          <button
            class="group cursor-pointer disabled:pointer-events-none"
            :disabled="isPending || isLoadingNextPage"
            type="submit"
          >
            <div class="relative z-0 p-4">
              <div
                class="relative z-0 -translate-y-4 rounded-full border border-navy bg-yellow-medium px-[70px] py-6 transition-all group-hover:-translate-y-2 group-active:translate-y-0 group-disabled:translate-y-0 group-disabled:border-inactive group-disabled:bg-inactive-bg"
              >
                <span
                  class="flex items-center gap-2 text-p1 font-bold uppercase text-navy group-disabled:text-inactive"
                >
                  <IconsLock class="h-5 w-5" />
                  {{
                    isPending || isLoadingNextPage ? "Signing in..." : "Sign in"
                  }}
                </span>

                <div
                  class="absolute inset-0 -z-10 flex overflow-hidden rounded-full"
                >
                  <div
                    class="grow -translate-x-full rounded-full bg-yellow-medium transition-all group-hover:-translate-x-0 group-active:-translate-x-full"
                  />
                </div>
              </div>

              <div
                class="absolute bottom-4 left-4 right-4 top-4 -z-10 rounded-full bg-navy"
              />

              <div
                class="absolute bottom-0 left-0 right-0 top-5 -z-20 rounded-full bg-white"
              />
            </div>
          </button>
        </div>

        <div class="flex items-center justify-center">
          <div class="text-sm">
            <NuxtLink
              to="/forgot-password"
              class="font-medium text-navy hover:opacity-90"
            >
              Forgot password?
            </NuxtLink>
          </div>
        </div>
      </form>
    </div>

    <NuxtImg
      ref="pig"
      src="/images/logo-icon-small.png"
      class="hidden w-full md:block md:h-[176px] md:w-[176px]"
      width="200"
      height="200"
    />
  </div>
</template>

<script lang="ts" setup>
import { toTypedSchema } from "@vee-validate/zod";
import { useForm } from "vee-validate";
import * as z from "zod";
import {
  FormField,
  FormItem,
  FormControl,
  FormLabel,
  FormMessage,
} from "~/components/Form";
import { useLoginMutation } from "~/domains/auth/mutations";

definePageMeta({
  layout: "login",
});

const meta = {
  title: "Sign In",
  description:
    "Access your Dollar Donation Club account to manage donations, track your impact, and support the causes you care about.",
  keywords: "Dollar Donation Club login,manage donations,track impact",
};

useSeoMeta({
  ...meta,
  ogTitle: meta.title,
  ogDescription: meta.description,
});

const formSchema = toTypedSchema(
  z.object({
    email: z.string().email(),
    password: z.string().min(1, "Required"),
  }),
);
const route = useRoute();
const { handleSubmit } = useForm({
  validationSchema: formSchema,
  initialValues: {
    email: (route.query.email as string | undefined) ?? "",
    password: "",
  },
});

const { mutate, isPending } = useLoginMutation();

const isLoadingNextPage = ref(false);
const submit = handleSubmit((values) =>
  mutate(values, {
    onSuccess() {
      isLoadingNextPage.value = true;
      const router = useRouter();
      const returnTo = router.currentRoute.value.query["return_to"];
      const path =
        returnTo && !Array.isArray(returnTo)
          ? decodeURIComponent(returnTo)
          : "/profile";
      const navigation = navigateTo(path);
      if (navigation instanceof Promise) {
        navigation.then(() => {
          setTimeout(() => {
            const hash = window.location.hash;
            if (hash) {
              const element = document.querySelector(hash);
              if (element) {
                element.scrollIntoView({ behavior: "smooth" });
              }
            }
          }, 100);
        });
      }
    },
  }),
);
</script>
